<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex ">{{$t('ACCOUNTING.ACC_MEMBER.ORDER_NR')}} {{order.order_nr}} <span v-show="!isEditable"> ({{$t('ENUMS.COMMONSTATE.CLOSED')}})</span>
          <div class="ml-auto text-right btn-alignment">
            <PollingButtonDoc :DocumentType="docType" :InitDocId="order.document_fk" :LinkedFk="order.id" Icon="get_app" Size="small" Color="primary" Type="filled" ButtonClass="" ref="OrderPollingBtn" v-on:clicked="DownloadOrder"></PollingButtonDoc>
            <!--<vs-button class="mr-1" size="small" color="primary" type="filled" icon="print" @click="PrintOrder()" :disabled="true"></vs-button>-->
            <vs-button size="small" color="primary" type="filled" icon="refresh" @click="RefreshData()"></vs-button>
            <vs-button size="small" color="primary" type="filled" icon="close" @click="CloseOrder()"></vs-button>
          </div>
        </h2> 
          <div id="ordermember_table" class="vs-con-loading__container" >
            <vs-tabs>
            <vs-tab :label="$t('ORDER.DISCOUNT.TAB_POS')">      
              <vs-table
                search
                :data="order.member"
                :noDataText="$t('ORDER.MEM.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat
                :multiple="isEditable"
                v-model="selectedMember"
                :disabled="!isEditable">

                <template slot="header">
                  <h3>
                    {{$t('ORDER.MEM.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="pos">
                    {{$t('ORDER.MEM.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="txt">
                    {{$t('ORDER.MEM.TABLE.COL2')}}
                  </vs-th>                  
                  <vs-th sort-key="amount">
                    {{$t('ORDER.MEM.TABLE.COL3')}}
                  </vs-th>        
                  <vs-th sort-key="netto">
                    {{$t('ORDER.MEM.TABLE.COL4')}}
                  </vs-th>  
                  <vs-th sort-key="mwst_val">
                    {{$t('ORDER.MEM.TABLE.COL5')}}
                  </vs-th>
                  <vs-th sort-key="brutto_sum">
                    {{$t('ORDER.MEM.TABLE.COL6')}}
                  </vs-th>   
                  <vs-th sort-key="verrechnet">
                    {{$t('ORDER.MEM.TABLE.COL7')}}
                  </vs-th>  
                  <vs-th sort-key="bezahlt">
                    {{$t('ORDER.MEM.TABLE.COL8')}}
                  </vs-th>
                  <vs-th sort-key="shipment_txt">
                    {{$t('ORDER.MEM.TABLE.COL9')}}
                  </vs-th>                                                                                                                                                                            
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">                    
                    <vs-td :data="data[indextr].pos">
                      {{data[indextr].pos}}
                    </vs-td>      
                    <vs-td :data="data[indextr].txt">
                      {{data[indextr].txt}}
                    </vs-td>                     
                    <vs-td :data="data[indextr].amount">
                      {{data[indextr].amount}}
                    </vs-td>
                    <vs-td :data="data[indextr].netto">
                      {{GetCurrencyString(data[indextr].netto)}}
                    </vs-td>                   
                    <vs-td :data="data[indextr].mwst_val">
                      {{data[indextr].mwst_val + ' %'}}
                    </vs-td>  
                    <vs-td :data="data[indextr].brutto_sum">
                      {{GetCurrencyString(data[indextr].brutto_sum)}}
                    </vs-td>          
                    <vs-td :data="data[indextr].verrechnet">
                      <div class="d-flex">
                        <vs-icon :icon="GetIcon(data[indextr],true)" :color="GetIconColor(data[indextr],true)" size="small"></vs-icon>
                        {{GetCurrencyString(data[indextr].verrechnet)}}
                      </div>
                    </vs-td> 
                    <vs-td :data="data[indextr].bezahlt">
                      <div class="d-flex">
                        <vs-icon :icon="GetIcon(data[indextr],false)" :color="GetIconColor(data[indextr],false)" size="small"></vs-icon>
                        {{GetCurrencyString(data[indextr].bezahlt)}}
                      </div>
                    </vs-td>
                    <vs-td :data="data[indextr].shipment_txt">
                      {{data[indextr].shipment_txt}}
                    </vs-td>                                                                                                                                                                                                 
                  </vs-tr>
                </template>
              </vs-table>
            </vs-tab> 
            <vs-tab :label="$t('ORDER.DISCOUNT.TAB_DISCOUNTS')">
              <div class="d-flex btn-alignment mb-3"> 
                <vs-button  :disabled="!isEditable" color="primary" type="filled" size="small" icon="add" @click="AddDiscount()">{{$t('ORDER.DISCOUNT.ADD')}}</vs-button> 
                <vs-button  :disabled="!isEditable" color="primary" type="filled" size="small" icon="refresh" @click="RecalcOrderDiscount()">{{$t('ORDER.DISCOUNT.RECALC')}}</vs-button>
              </div>
              <div>
              <vs-table
                search
                :data="order.discountmember"
                :noDataText="$t('ORDER.DISCOUNT.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('ORDER.DISCOUNT.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                                          
                  <vs-th sort-key="discounttype">
                    {{$t('ORDER.DISCOUNT.TABLE.COL2')}}
                  </vs-th>  
                  <vs-th sort-key="description">
                    {{$t('ORDER.DISCOUNT.TABLE.COL1')}}
                  </vs-th>                                   
                  <vs-th sort-key="discount_proz">
                    {{$t('ORDER.DISCOUNT.TABLE.COL4')}}
                  </vs-th>  
                  <vs-th sort-key="valid_days">
                    {{$t('ORDER.DISCOUNT.TABLE.COL5')}}
                  </vs-th>
                  <vs-th sort-key="ignore_valid_days">
                    {{$t('ORDER.DISCOUNT.TABLE.COL6')}}
                  </vs-th>  
                  <vs-th sort-key="printable">
                    {{$t('ORDER.DISCOUNT.TABLE.COL7')}}
                  </vs-th> 
                  <vs-th>
                  </vs-th>                                                                                                                                                                            
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">                  
                    <vs-td :data="data[indextr].discounttype">
                      <div class="d-flex">
                        <vs-icon :icon="GetIconDiscount(data[indextr])" :color="GetIconColorDiscount(data[indextr])" size="small" class="mr-1"></vs-icon>
                        <span>{{GetDiscountTypesText(data[indextr].discounttype)}}</span>
                      </div>
                    </vs-td>
                    <vs-td :data="data[indextr].description">
                      {{data[indextr].description}}
                    </vs-td>                  
                    <vs-td :data="data[indextr].discount_proz">
                      {{Number(data[indextr].discount_proz).toFixed(2)}}% ({{GetCurrencyString(data[indextr].discount_amount)}})
                    </vs-td>  
                    <vs-td :data="data[indextr].valid_days">
                      {{data[indextr].valid_days}}
                    </vs-td>          
                    <vs-td :data="data[indextr].ignore_valid_days">
                      <vs-checkbox :disabled="true" v-model="data[indextr].ignore_valid_days"></vs-checkbox>
                    </vs-td> 
                    <vs-td :data="data[indextr].printable">
                      <vs-checkbox :disabled="true" v-model="data[indextr].printable"></vs-checkbox>
                    </vs-td> 
                    <vs-td>
                      <div class="btn-alignment">
                      <vs-button :disabled="!isEditable" @click="EditDiscount(data[indextr])" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button :disabled="!isEditable" @click="OpenConfirmDeleteDiscount(data[indextr].id,LimitTextTo(data[indextr].description,20))" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>  
                      </div>
                    </vs-td>                                                                                                                                                                                                  
                  </vs-tr>
                </template>
              </vs-table>        
              </div>
            </vs-tab>
            <vs-tab :label="$t('ORDER.TAB_CONFIRMS')">
              <div>
              <vs-table
                :data="order.confirmationmember"
                :noDataText="$t('ORDER.CONFIRMS.NO_DATA')"
                stripe
                max-items="25" 
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('ORDER.CONFIRMS.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                                          
                  <vs-th sort-key="confirmation_nr">
                    {{$t('ORDER.CONFIRMS.TABLE.COL1')}}
                  </vs-th>  
                  <vs-th sort-key="created">
                    {{$t('ORDER.CONFIRMS.TABLE.COL2')}}
                  </vs-th>                                   
                  <vs-th>
                  </vs-th>                                                                                                                                                                            
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">                  
                    <vs-td :data="data[indextr].confirmation_nr">
                      {{data[indextr].confirmation_nr}}
                    </vs-td>                  
                    <vs-td :data="data[indextr].created">
                      {{DateToString(data[indextr].created,false)}}
                    </vs-td>  
                    <vs-td>
                      <div class="btn-alignment">
                        <vs-button @click="PrintAB(data[indextr])" size="small" color="primary" type="filled" icon="print"></vs-button>  

                        <PollingButtonDoc ref="pollingBtnAB" :DocumentType="docTypeAb" :InitDocId="data[indextr].document_fk" :LinkedFk="data[indextr].id" Icon="get_app" Size="small" Color="primary" Type="filled" ButtonClass="ml-2" v-on:clicked="DownloadAb"></PollingButtonDoc>
                        <vs-button :disabled="data[indextr].document_fk <= 0" @click="ViewABPdf(data[indextr].document_fk)" size="small" color="primary" type="filled" icon="open_in_new"></vs-button> 
                        <SendDebitorDocuemntMailBtn :doc_nr="data[indextr].confirmation_nr" :doc_id="data[indextr].id" :doc_type="docTypeAb"></SendDebitorDocuemntMailBtn>
                      </div>
                    </vs-td>                                                                                                                                                                                                  
                  </vs-tr>
                </template>
              </vs-table>        
              </div>
            </vs-tab>
            <vs-tab :label="$t('ORDER.TAB_SHIPMENTS')">
              <div>
              <vs-table
                search
                :data="shipments"
                :noDataText="$t('ORDER.SHIPMENTS.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('ORDER.SHIPMENTS.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                                          
                  <vs-th sort-key="shipment_nr">
                    {{$t('ORDER.SHIPMENTS.TABLE.COL1')}}
                  </vs-th> 
                  <vs-th sort-key="deliverycondition_fk">
                    {{$t('ORDER.SHIPMENTS.TABLE.COL2')}}
                  </vs-th> 
                  <vs-th sort-key="sent_date">
                    {{$t('ORDER.SHIPMENTS.TABLE.COL3')}}
                  </vs-th>  
                  <vs-th sort-key="state">
                    {{$t('ORDER.SHIPMENTS.TABLE.COL4')}}
                  </vs-th> 
                  <vs-th sort-key="last_change">
                    {{$t('ORDER.SHIPMENTS.TABLE.COL5')}}
                  </vs-th>                                  
                  <vs-th>
                  </vs-th>                                                                                                                                                                            
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">                  
                    <vs-td :data="data[indextr].shipment_nr">
                      {{data[indextr].shipment_nr}}
                    </vs-td>   
                    <vs-td :data="data[indextr].deliverycondition_fk">
                      {{GetDeliveryConditionString(data[indextr].deliverycondition_fk)}}
                    </vs-td>                
                    <vs-td :data="data[indextr].sent_date">
                      <span v-show="ShowSentDate(data[indextr].state)">{{$t('ORDER.SHIPMENTS.SENT_DATE')}} {{DateToString(data[indextr].sent_date,true)}}</span><br  v-show="ShowReceivedDate(data[indextr].state)" />
                      <span v-show="ShowReceivedDate(data[indextr].state)">{{$t('ORDER.SHIPMENTS.REC_DATE')}} {{DateToString(data[indextr].received_date,true)}}</span><br  v-show="ShowReturnedDate(data[indextr].state)" />
                      <span v-show="ShowReturnedDate(data[indextr].state)">{{$t('ORDER.SHIPMENTS.RET_DATE')}} {{DateToString(data[indextr].returned_date,true)}}</span>
                    </vs-td> 
                    <vs-td :data="data[indextr].state">
                      <ShipmentStatusComp v-model="data[indextr].state" :OwnerFk="data[indextr].id" v-on:StatusChanged="UpdateShipmentStatus"></ShipmentStatusComp>
                    </vs-td> 
                    <vs-td :data="data[indextr].last_change">
                      {{DateToString(data[indextr].last_change,false)}}
                    </vs-td>              
                    <vs-td>
                      <div class="d-flex">
                        <PollingButtonDoc ref="pollingShipment" :DocumentType="docTypeShipment" :InitDocId="data[indextr].document_fk" :LinkedFk="data[indextr].id" Icon="get_app" Size="small" Color="primary" Type="filled" ButtonClass="mr-1" v-on:clicked="DownloadShipment"></PollingButtonDoc>
                        <SendDebitorDocuemntMailBtn class="mr-1" :doc_nr="data[indextr].shipment_nr" :doc_id="data[indextr].id" :doc_type="docTypeShipment"></SendDebitorDocuemntMailBtn>
                        <ButtonList :buttonArray="[{label:$t('ORDER.SHIPMENTS.BTN.PRINT'),color:'primary',type:'filled',icon:'print',show:true, disabled:false,data:[data[indextr],indextr]},
                                       {label:$t('ORDER.SHIPMENTS.BTN.SHOW'),color:'primary',type:'filled',icon:'open_in_new',show:true,disabled:data[indextr].document_fk <= 0,data:[data[indextr].document_fk]},
                                       {label:$t('ORDER.SHIPMENTS.BTN.EDIT'),color:'primary',type:'filled',icon:'edit',show:true,disabled:false,data:[data[indextr]]},
                                       {label:$t('ORDER.SHIPMENTS.BTN.DELETE'),color:'primary',type:'filled',icon:'delete',show:true,disabled:!isEditable,data:[data[indextr].id,data[indextr].shipment_nr]}
                                      ]" v-on:BtnClicked="ButtonListClicked"></ButtonList>
                        
                      </div>
                    </vs-td>                                                                                                                                                                                                  
                  </vs-tr>
                </template>
              </vs-table>        
              </div>
            </vs-tab>
            </vs-tabs>
          <div style="padding:10px" class="mt-3">
          <vs-card>
            <vs-list>
              <vs-list-header :title="$t('ORDER.NUMBERS_HEADER')"></vs-list-header>
              <vs-list-item :title="$t('ORDER.NOT_INVOICED')" :subtitle="$t('ORDER.NOT_INVOICED_SUB')"><vs-chip class="ml-2"><h4>{{GetCurrencyString(sumOffen)}}</h4></vs-chip></vs-list-item>
              <vs-list-item :title="$t('ORDER.NOT_PAYED')" :subtitle="$t('ORDER.NOT_PAYED_SUB')"><vs-chip class="ml-2"><h4>{{GetCurrencyString(sumVerr-sumBezahlt)}}</h4></vs-chip></vs-list-item>
              <vs-list-item v-show="this.selectedMember.length > 0" :title="$t('ORDER.NOT_INVOICED_PART')" :subtitle="$t('ORDER.NOT_INVOICED_PART_SUB')"><vs-chip class="ml-2"><h4>{{GetCurrencyString(sumSelected)}}</h4></vs-chip></vs-list-item>
              <vs-list-item :title="$t('ORDER.DISCOUNT_SUM')" :subtitle="$t('ORDER.DISCOUNT_SUM_SUB')"><vs-chip class="ml-2"><h4>{{GetCurrencyString(sumDiscount)}}</h4></vs-chip></vs-list-item>
            </vs-list>
            <div class="btn-alignment">
              <vs-button v-show="isGesamtRechPossible" @click="CreateGesamt()"  color="primary" type="filled" icon="arrow_forward">{{$t('ORDER.MEM.CREATE_GESAMT')}}</vs-button> 
              <vs-button v-show="isTeilrechPossible" @click="CreateTeil()"  color="primary" type="filled" icon="arrow_forward">{{$t('ORDER.MEM.CREATE_TEIL')}}</vs-button> 
              <vs-button v-show="isAnzPossible" @click="CreateAnz()"  color="primary" type="filled" icon="arrow_forward">{{$t('ORDER.MEM.CREATE_ANZAHLUNG')}}</vs-button> 
              <vs-button v-show="isSchlussPossible" @click="CreateSchluss()"  color="primary" type="filled" icon="arrow_forward">{{$t('ORDER.MEM.CREATE_SCHLUSS')}}</vs-button> 
              <vs-button v-show="isFinishPossible" @click="FinishOffer()"  color="success" type="filled" icon="check">{{$t('ORDER.MEM.FINISH')}}</vs-button> 
              <vs-button v-show="isClosePossible" @click="CloseNotFinishOffer()"  color="warning" type="filled" icon="check">{{$t('ORDER.MEM.CLOSE_NOT_FINISH')}}</vs-button>
              <vs-button v-show="isShipmentPossible" @click="CreateShipment()"  color="primary" type="filled" icon="local_shipping">{{$t('ORDER.MEM.CREATE_SHIPMENT')}}</vs-button> 
            </div>
          </vs-card>
          </div>
        </div> 

        <AddInvoiceDlg ref="addInvoiceDlg"  v-on:Success="InvoiceAdded"></AddInvoiceDlg>
        <AddOrderDiscountDlg ref="addOrderDiscountDlg" v-on:Success="DiscountAdded" :LanguageFk="this.order.language_fk" :CurrencyKuerzel="this.order.currency_kuerzel" :OrderSum="sumBrutto"></AddOrderDiscountDlg>
        <AddShipmentDlg ref="addShipmentDlg" :Type="ownerTypeShipment" :CountryFk="order.country_fk" :CurrencyFk="order.currency_fk" :OwnerFk="order.id" v-on:Success="ShipmentChanged"></AddShipmentDlg>
        <vs-popup fullscreen :title="$t('ORDER.POPUP_DOCUMENT')" :active.sync="pdfPopupActive">
            <PdfJsViewer ref="pdfViewerOrder" fileName=""></PdfJsViewer>
        </vs-popup>
        <vs-card>
          <vs-button v-show="isEditable" size="small" @click="$refs.addRemoveInvoiceDlg.ShowDlg(actDebitorContactFk,order.id)">{{$t('ORDER.ADD_REMOVE_DLG')}}</vs-button>
          <AddRemoveInvoiceDlg ref="addRemoveInvoiceDlg" v-on:Success="InvoiceAddedRemoved"></AddRemoveInvoiceDlg>
          <InvoiceTable ref="invoiceTable" :InvoiceForOrder="true" v-model="actOrderId" v-on:DataChanged="InvoicesChanged" v-on:PaymentAdded="PaymentAdded" :isDisabled="!isEditable"></InvoiceTable>
        </vs-card>
      </vs-card>
    </vs-col>
  </vs-row>
</div>
</template>
<script>

import helperMethods from '../../helper/staticFuncHelper';
import codeHelper from '../../helper/staticCodeHelper';
import enumHelper from "../../helper/enumHelper";
import downloadMethods from '../../helper/staticDownloadFuncHelper';
import {documentType} from "../../helper/enumHelper";
import PollingButtonDoc from './PollingButtonDoc.vue';
import AddInvoiceDlg from './dialogs/AddInvoiceDlg.vue';
import {invoiceTypes} from "../../helper/enumHelper";
import InvoiceTable from "./tables/InvoiceTable.vue";
import {commonStates} from "../../helper/enumHelper";
import AddRemoveInvoiceDlg from "./dialogs/AddRemoveInvoiceDlg.vue";
import AddOrderDiscountDlg from "./dialogs/AddOrderDiscountDlg.vue";
import AddShipmentDlg from "./dialogs/AddShipmentDlg.vue";
import {shipmentOwnerType} from "../../helper/enumHelper";
import ShipmentStatusComp from "./ShipmentStatus.vue";
import PdfJsViewer from './PdfJsViewer.vue';
import {shipmentStatus} from '../../helper/enumHelper';
import ButtonList from './ButtonList.vue';
import SendDebitorDocuemntMailBtn from './dialogs/SendDebitorDocuemntMailBtn.vue';

export default {
  name: "Order",
  props:['InitData'],
  components:{
    PollingButtonDoc,
    AddInvoiceDlg,
    InvoiceTable,
    AddRemoveInvoiceDlg,
    AddOrderDiscountDlg,
    AddShipmentDlg,
    ShipmentStatusComp,
    PdfJsViewer,
    ButtonList,
    SendDebitorDocuemntMailBtn
  },
  data: function (){
     return {
      order:{},
      docType:documentType.ORDER,
      docTypeAb:documentType.AB,
      ownerTypeShipment:shipmentOwnerType.ORDER,
      docTypeShipment:documentType.DELIVERY,
      editable:true,
      selectedMember:[],
      isMultible:true,
      invoicesForOrder:[],
      actOrderId:0,
      shipments:[],
      pdfPopupActive:false
     }
  }, 
  mounted () 
  {
    this.actOrderId = this.$route.params.order_fk;
   },
  beforeRouteEnter(to, from, next)
  {
    next(vm => {
      vm.prevRoute = from;
    })
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...downloadMethods,
      ...enumHelper,
      RefreshData(){
        this.$store.dispatch('accounting/getOffersByOrder',this.actOrderId )
        .then(data => {
            if(this.IsNotNull(data))
            {
              this.$store.dispatch('debitor/getDebitorByOrder',this.actOrderId )
              .then(data => {
                if(this.IsNotNull(data))
                {
                  this.$store.dispatch('accounting/getOrder',this.actOrderId )
                  .then(data => {
                      if(this.IsNotNull(data))
                      {
                        this.order = data;
                      }
                    }, 
                    error =>{
                      this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('ORDER.ERROR.REFRESH')+' '+error});
                    });
                }
              }, 
              error =>{
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('ORDER.ERROR.REFRESH')+' '+error});
              });
            }
          }, 
          error =>{
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('ORDER.ERROR.REFRESH')+' '+error});
          });

        this.$store.dispatch('shipment/getShipments',this.actOrderId );
        this.$store.dispatch('deliverycondition/getDeliveryConditions');
        this.$refs.invoiceTable.RefreshData();
        this.selectedMember = [];
      },
      ButtonListClicked(index, data){
      switch(index)
      {
        case 0:
          this.PrintShipment(data[0])
          break;
        case 1:
          this.ViewShipmentPdf(data[0])
          break;
        case 2:
          this.EditShipment(data[0])
          break;  
        case 3:
          this.OpenConfirmDeleteShipment(data[0],data[1]);
          break;                                   
      }
      
    },
      CloseOrder(){       
        this.$router.push({ name: 'DebitorContact', params: { debitorcontact_fk: this.order.debitorcontact_fk } });
      },
      DownloadOrder(actDocId)
      {
        if(this.IsNotNull(this.order))
        {
          var docId = actDocId;
          if(this.order.document_fk > 0 && this.order.document_fk != docId)
          {
            docId = this.order.document_fk;
          }
          else
          {
            this.order.document_fk = actDocId;
          }
          this.$store.dispatch('document/downloadDocument', docId)
              .then(response => {
                  this.DownloadWithFileSaver(response);
                }
              );
        }
      }, 
      PrintOrder()
      {
        var name = this.order.bez;
        this.$store.dispatch('document/createDocument', {id:this.order.id,name:this.order.order_nr, type: documentType.ORDER})
          .then(success => {
            if(success === true)
            {
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ORDER.SUCCESS.PRINT').replace("*1*",name)});
            }
            else
            {
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('ORDER.ERROR.PRINT').replace("*1*",name)});
            }
          }, 
          error =>{
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('ORDER.ERROR.PRINT').replace("*1*",name)+' '+error});
          }); 
      },
      GetIcon(mem,verr){
        var icon = "check";
        if(this.IsNotNull(mem))
        {
          var diff = mem.verrechnet - mem.bezahlt;
          if(verr)
          {
            diff = mem.brutto_sum - mem.verrechnet;
          }

          if( diff >= this.GetPaymentDiff() )
          {
            icon = "clear";
          }
        }
        return icon;
      },
      GetIconColor(mem,verr){
        var color = "success";
        if(this.IsNotNull(mem))
        {
          var diff = mem.verrechnet - mem.bezahlt;
          if(verr)
          {
            diff = mem.brutto_sum - mem.verrechnet;
          }

          if(diff >= this.GetPaymentDiff() )
          {
            color = "danger";
          }
        }
        return color;
      },
      GetIconDiscount(mem){
        var icon = "help";
        if(this.IsNotNull(mem))
        {
          if( mem.used == true )
          {
            icon = "check";
          }

          if( mem.ignoredCauseInvalid == true )
          {
            icon = "warning";
          }
        }
        return icon;
      },
      GetIconColorDiscount(mem){
        var color = "success";
        if(this.IsNotNull(mem))
        {
          if(mem.ignoredCauseInvalid == true )
          {
            color = "warning";
          }
        }
        return color;
      },      
      GetCurrencyString(amount){
        var retVal = "";
        if(this.IsNotNull(this.order))
        {

          if(this.IsNotNull(this.order.currency_kuerzel) && this.order.language_fk >0)
          {
            retVal = this.DecimalToString(amount,this.order.currency_kuerzel,this.GetLocalFormatOfLanguage(this.order.language_fk));
          }
        }
        return retVal;
      },
      GetDeliveryConditionString(id)
      {
        var retVal = "";
        var infos = this.GetCurrencyCountryByOrderFk(this.order.id);
        var deliveries = this.GetListOfDeliveryConditionsForSelect(infos.country_fk,infos.currency_fk);
        if(this.IsNotNull(deliveries))
        {
          for(var i =0; i < deliveries.length; i++)
          {
            if(deliveries[i].value == id)
            {
              retVal = deliveries[i].text;
              break;
            }
          }
        }
        return retVal;
      },
      DownloadAb(doc_id)
      {
        if(this.IsNotNull(doc_id))
        {
            this.$store.dispatch('document/downloadDocument', doc_id)
                .then(response => {
                    this.DownloadWithFileSaver(response);
                  }
                );
        }
      },
      PrintAB(data)
      {
        var name = data.confirmation_nr;
        this.$store.dispatch('document/createDocument', {id:data.id,name:data.confirmation_nr, type: documentType.AB})
          .then(success => {
            if(success === true)
            {
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ORDER.CONFIRMATION.SUCCESS.PRINT').replace("*1*",name)});
            }
            else
            {
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('ORDER.CONFIRMATION.ERROR.PRINT').replace("*1*",name)});
            }
          }, 
          error =>{
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('ORDER.CONFIRMATION.ERROR.PRINT').replace("*1*",name)+' '+error});
          }); 
      },
      ViewABPdf(docId)
      {
        if(this.IsNotNull(docId))
        {
          this.$store.dispatch('document/downloadDocument', docId)
              .then(response => {
                  this.$refs.pdfViewerOrder.LoadPdfBlobToViewer(response);
                  this.pdfPopupActive = true;
                }
              );
        }
      },
      DownloadShipment(doc_id)
      {
        if(this.IsNotNull(doc_id))
        {
            this.$store.dispatch('document/downloadDocument', doc_id)
                .then(response => {
                    this.DownloadWithFileSaver(response);
                  }
                );
        }
      },
      PrintShipment(data)
      {
        var name = data.shipment_nr;
        this.$store.dispatch('document/createDocument', {id:data.id,name:data.shipment_nr, type: documentType.DELIVERY})
          .then(success => {
            if(success === true)
            {
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ORDER.SHIPMENTS.SUCCESS.PRINT').replace("*1*",name)});
            }
            else
            {
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('ORDER.SHIPMENTS.ERROR.PRINT').replace("*1*",name)});
            }
          }, 
          error =>{
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('ORDER.SHIPMENTS.ERROR.PRINT').replace("*1*",name)+' '+error});
          }); 
      },
      ViewShipmentPdf(docId)
      {
        if(this.IsNotNull(docId))
        {
          this.$store.dispatch('document/downloadDocument', docId)
              .then(response => {
                  this.$refs.pdfViewerOrder.LoadPdfBlobToViewer(response);
                  this.pdfPopupActive = true;
                }
              );
        }
      },
      EditShipment(data)
      {
        var infos = this.GetCurrencyCountryByOrderFk(this.order.id);
        this.$refs.addShipmentDlg.InitDlg(this.order.id,infos.currency_fk,infos.country_fk,data);
      },
      ShipmentChanged(){
        this.RefreshData();
      },
      UpdateShipmentStatus(newStatus,id){
        var shipment={id:id,state: newStatus};
        this.$store.dispatch('shipment/updateShipmentState', shipment)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('SHIPMENT.ADD_SHIPMENT.SUCCESS.SAVE')});  
                this.RefreshData();
            }
            else
            {
              var text = this.$t('SHIPMENT.ADD_SHIPMENT.WARNING.SAVE');
              text = text.replace("*1*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('SHIPMENT.ADD_SHIPMENT.ERROR.SAVE') + error});  
          });
      },
      OpenConfirmDeleteShipment(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('ORDER.SHIPMENT.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteShipmentRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      SendDeleteShipmentRequest: function(parameters)
      {
        this.$store.dispatch('shipment/deleteShipment', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.RefreshData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ORDER.SHIPMENTS.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('ORDER.SHIPMENTS.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('ORDER.SHIPMENTS.ERROR.DELETE').replace("*1*",error)});  
        });
      },
      CreateSchluss()
      {
        this.CreateInvoice(invoiceTypes.SCHLUSS);
      },
      CreateAnz()
      {
        this.$refs.addInvoiceDlg.ShowDlg(this.order.id,this.order.order_nr,this.order.member,invoiceTypes.ANZAHLUNG,this.order.language_fk,this.order.currency_kuerzel);
      },
      CreateTeil()
      {
        this.CreateInvoice(invoiceTypes.TEIL);
      },
      CreateGesamt()
      {
        this.CreateInvoice(invoiceTypes.GESAMT);
      },
      CreateInvoice(type)
      {
        this.$refs.addInvoiceDlg.ShowDlg(this.order.id,this.order.order_nr,this.selectedMember,type,this.order.language_fk,this.order.currency_kuerzel);
      },
      CreateShipment(){
        var infos = this.GetCurrencyCountryByOrderFk(this.order.id);
        this.$refs.addShipmentDlg.ShowDlg(this.selectedMember,this.order.id,infos.currency_fk,infos.country_fk);
      },
      InvoiceAdded(){
        this.RefreshData();
      },
      InvoicesChanged(){
        this.RefreshData();
      },
      PaymentAdded(){
        this.RefreshData();
      },
      FinishOffer(){
        var data = {orderFk:this.order.id,finished:true,state:commonStates.CLOSED};
        this.$store.dispatch('accounting/finishOrder', data)
        .then(response => {
              if(response.success == true)
              {
                // RAl Colors laden, eventuell gibt es neue Standard Farben
                this.$store.dispatch('ralcolor/getAllRalColors',{ root: true },{ root: true });
                this.CloseOrder();
              }
          }
        );
       
      },
      CloseNotFinishOffer(){
        var data = {orderFk:this.order.id,finished:false,state:commonStates.CLOSEDBUTNOTFINISHED};
        this.$store.dispatch('accounting/finishOrder', data)
        .then(response => {
              if(response.success == true)
              {
              this.CloseOrder();
              }
          }
        );
      },
      InvoiceAddedRemoved(){
        // Invoicetable neu laden
        this.RefreshData();
      },
      RecalcOrderDiscount(){
          
          this.$store.dispatch('accounting/recalcOrderDiscount', {order_fk:this.order.id})
          .then(response => {
              if(response.success == true)
              {
                this.RefreshData();
              }
          }
        );
      },
      AddDiscount()
      {
        this.$refs.addOrderDiscountDlg.ShowDlg(this.order.id);
      },
      EditDiscount(data)
      {
         this.$refs.addOrderDiscountDlg.InitDlgData(data);
      },
      DiscountAdded()
      {
        this.RecalcOrderDiscount();
      
      },
      OpenConfirmDeleteDiscount(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('ORDER.DISCOUNT.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('accounting/deleteOrderDiscount', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.RefreshData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ORDER.DISCOUNT.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('ORDER.DISCOUNT.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('ORDER.DISCOUNT.ERROR.DELETE').replace("*1*",error)});  
        });
      },
      ShowReceivedDate(state)
      {
          var retVal = false;
          switch(state)
          {
            case shipmentStatus.DELIVERED:
            case shipmentStatus.ONTHEWAYBACK:
            case shipmentStatus.RETURNED:
              retVal = true;
              break;
          }
          return retVal;
      },
      ShowReturnedDate(state)
      {
          var retVal = false;
          switch(state)
          {
            case shipmentStatus.RETURNED:
              retVal = true;
              break;
          }
          return retVal;
      },
      ShowSentDate(state)
      {
          var retVal = false;
          switch(state)
          {
            case shipmentStatus.RETURNED:
            case shipmentStatus.ONTHEWAY:
            case shipmentStatus.DELIVERED:
            case shipmentStatus.ONTHEWAYBACK:
            case shipmentStatus.LOST:
              retVal = true;
              break;
          }
          return retVal;
      }
  },
  computed:{
    loadingOrderData () {
      var loading = false;
      if(typeof(this.$store.state.accounting.orders.status) != 'undefined')
        loading = this.$store.state.accounting.orders.status.loading;
      return loading;
    },
    actDebitorContactFk(){
      var retVal = 0;
      if(this.IsNotNull(this.order))
      {
        retVal = this.order.debitorcontact_fk;
      }
      
      return retVal;
    },
    shipmentTableData(){
      var data = [];
      if(this.$store.state.shipment.shipments.data != null)
      {
        data = this.$store.state.shipment.shipments.data;
      }
      return data;
    },
    invoices(){
      var data = [];
      if(this.$store.state.accounting.invoicesForOrder.data != null)
      {
        data = this.$store.state.accounting.invoicesForOrder.data;
      }
      return data;
    },
    sumOffen(){
      var sum = 0;

      if(this.IsNotNull(this.order))
      {
        if(this.IsNotNull(this.order.member))
        {
          this.order.member.forEach(mem =>{
            sum +=  mem.brutto_sum - mem.verrechnet;
          });
        }
      }

      return sum;
    },
    sumBrutto(){
      var sum = 0;

      if(this.IsNotNull(this.order))
      {
        if(this.IsNotNull(this.order.member))
        {
          this.order.member.forEach(mem =>{
            sum +=  mem.brutto_sum;
          });
        }
      }

      return sum;
    },
    sumSelected(){
      var sum = 0;

      if(this.IsNotNull(this.order))
      {
        if(this.IsNotNull(this.order.member) && this.IsNotNull(this.selectedMember))
        {
          this.selectedMember.forEach(mem =>{
            sum +=  mem.brutto_sum - mem.verrechnet;
          });
        }
      }

      return sum;
    },
    sumBezahlt(){
      var sum = 0;

      if(this.IsNotNull(this.order))
      {
        if(this.IsNotNull(this.order.member))
        {
          this.order.member.forEach(mem =>{
            sum += mem.bezahlt;
          });
        }
      }

      return sum;
    },
    sumVerr(){
      var sum = 0;

      if(this.IsNotNull(this.order))
      {
        if(this.IsNotNull(this.order.member))
        {
          this.order.member.forEach(mem =>{
            sum += mem.verrechnet;
          });
        }
      }

      return sum;
    },
    sumDiscount(){
      var sum = 0;

      if(this.IsNotNull(this.order))
      {
        if(this.IsNotNull(this.order.discountmember))
        {
          this.order.discountmember.forEach(mem =>{
            sum += mem.discount_amount;
          });
        }
      }

      return sum;
    },
    isTeilrechPossible(){
      return Math.abs(this.sumSelected) > 0 && Math.abs(this.sumBrutto - this.sumOffen) <=  this.GetPaymentDiff()&& this.isEditable;
    },
    isGesamtRechPossible(){
      return Math.abs(this.sumBrutto - this.sumOffen) <=  this.GetPaymentDiff()&& this.isEditable;
    },
    isAnzPossible(){
      return Math.abs(this.sumBrutto - this.sumOffen) <=  this.GetPaymentDiff()&& this.isEditable;
    },
    isSchlussPossible(){
      return Math.abs(this.sumOffen) > this.GetPaymentDiff() && this.isEditable && Math.abs(this.sumVerr) > this.GetPaymentDiff();
    },
    isFinishPossible(){
      return Math.abs(this.sumBrutto - this.sumBezahlt) <=  this.GetPaymentDiff() && Math.abs(this.sumBrutto - this.sumVerr)  <=  this.GetPaymentDiff() && this.isEditable;
    },
    isClosePossible(){
      return Math.abs(this.sumBrutto - this.sumBezahlt) >  this.GetPaymentDiff() && this.isEditable;
    },
    isEditable(){
      var retVal = false;
      if(this.IsNotNull(this.order))
      {
        retVal = this.isEditOrderAllowed(this.order.state);
      }
      return retVal;
    },
    isShipmentPossible(){
      return this.selectedMember.length > 0 && this.isEditable;
    }
  },
  watch:{
    invoices(value)
    {
      this.invoicesForOrder = value;
    },
    loadingOrderData(value)
    {
      if(value)
      {
        this.$store.dispatch('alertqueue/showLoader', {'id':'#ordermember_table'});
      }
      else
      {
        this.$store.dispatch('alertqueue/hideLoader', {'id':'#ordermember_table'});
      }
    },
    shipmentTableData(value)
    {
      this.shipments = value;
    },
    actOrderId()
    {
      this.RefreshData();
    }
  }
};
</script>