<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.OK')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddShipment"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('SHIPMENT.ADD_SHIPMENT.HEADER.TEXT')}}</p>
          <vs-tabs >
            <vs-tab :label="$t('SHIPMENT.SETTINGS')" >
              <ShipmentStatusCompo :disabled="stateDisabled" v-model="state"></ShipmentStatusCompo>
              <div class="default-input d-flex align-items-center">{{$t('SHIPMENT.ADD_SHIPMENT.SHIPMENT_NUMBERGROUP')}} 
                <vs-select :disabled="shipment_nr.length > 0" v-model="numbergroup_fk" class="ml-2" :danger="numbergroup_fk_fail">
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in numbergroups" />
                </vs-select>
              </div>
              <div class="default-input d-flex align-items-center">
                <vs-select :label="$t('SHIPMENT.ADD_SHIPMENT.LABEL.DELIVERYCOND')" v-model="deliverycondition_fk">
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in deliveryconditions" />
                </vs-select>
              </div>
              <div class="default-input clearfix align-items-center mb-3 mt-2">
                <DatePicker  class="float-left" v-show="ShowSentDate(state)" :Label="$t('SHIPMENT.ADD_SHIPMENT.LABEL.SENT_DATE')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('SHIPMENT.ADD_SHIPMENT.POPUP.TITLE.SENT_DATE')" v-model="sent_date"></DatePicker>
                <DatePicker  class="float-left" v-show="ShowReceivedDate(state)" :Label="$t('SHIPMENT.ADD_SHIPMENT.LABEL.REC_DATE')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('SHIPMENT.ADD_SHIPMENT.POPUP.TITLE.REC_DATE')" v-model="received_date"></DatePicker>
                <DatePicker  class="float-left" v-show="ShowReturnedDate(state)" :Label="$t('SHIPMENT.ADD_SHIPMENT.LABEL.RET_DATE')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('SHIPMENT.ADD_SHIPMENT.POPUP.TITLE.RET_DATE')" v-model="returned_date"></DatePicker>
              </div>
              <vs-textarea height="100px" counter="500" :label="$t('SHIPMENT.ADD_SHIPMENT.LABEL.TEXT')" :counter-danger.sync="description_fail" v-model="description" />
            </vs-tab>
            <vs-tab :label="$t('SHIPMENT.MEMBER')">
              <div v-show="this.editmode_mem" >
                <div class="default-input d-flex align-items-center mb-2">
                  <vs-input :label="$t('SHIPMENT.MEM.EDIT_PROD')" class="mr-1" v-model="actmember.txt_prod"></vs-input>
                  <vs-input :label="$t('SHIPMENT.MEM.EDIT_TXT')" class="mr-1" v-model="actmember.txt_var"></vs-input>
                  <vs-input :label="$t('SHIPMENT.MEM.EDIT_AMOUNT')" type="number" class="mr-3" v-model="actmember.amount" :min="0"></vs-input>
                  <vs-button icon="check" class="mr-1" @click="SaveMember"></vs-button>
                  <vs-button icon="clear" @click="CancelMember"></vs-button>
                </div>
                <div class="default-input d-flex align-items-center">
                  <vs-checkbox v-model="actmember.show_position" >{{$t('SHIPMENT.MEM.EDIT_SHOWPOS')}}
                    <vs-icon v-show="actmember.show_position == false" color="primary" class="small_icon" icon="visibility_off"></vs-icon>
                  </vs-checkbox>
                </div>
              </div>
              <div v-show="this.editmode_mem == false" >
              <div class="default-input d-flex align-items-center mb-2" >
                <vs-button size = "small" v-show="this.editmode_mem == false && IsNotNull(this.fulldata)" icon="add" class="mr-2" @click="editmode_mem = true"></vs-button>
                <vs-button size = "small" v-show="IsNotNull(this.fulldata)" icon="refresh" class="mr-1 ml-2" @click="OpenConfirmMerge(fulldata.id,EQUALPROD)">{{$t('SHIPMENT.ADD_SHIPMENT.MERGE_PROD')}}</vs-button>
                <vs-button size = "small" v-show="IsNotNull(this.fulldata)" icon="refresh" @click="OpenConfirmMerge(fulldata.id,EQUAL)">{{$t('SHIPMENT.ADD_SHIPMENT.MERGE_EQUAL')}}</vs-button>
              </div>
              <div id="shipment_member_table" class="vs-con-loading__container" >
                <vs-table
                  search
                  :data="shipmentMemberFull"
                  :noDataText="$t('SHIPMENT.MEM.NO_DATA')"
                  stripe
                  max-items="8" 
                  pagination
                  hoverFlat>

                  <template slot="header">
                    <h3>
                      {{$t('SHIPMENT.MEM.TABLE.HEADER')}}
                    </h3>
                  </template>
                  <template slot="thead">                               
                    <vs-th sort-key="pos">
                      {{$t('SHIPMENT.MEM.TABLE.COL1')}}
                    </vs-th>
                    <vs-th sort-key="txt_prod">
                      {{$t('SHIPMENT.MEM.TABLE.COL2')}}
                    </vs-th>                       
                    <vs-th sort-key="amount">
                      {{$t('SHIPMENT.MEM.TABLE.COL3')}}
                    </vs-th>   
                    <vs-th></vs-th>                                                                                                                                          
                  </template>

                  <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data" >                    
                      <vs-td :data="data[indextr].pos">
                        
                        <vs-button :disabled="indextr <= 0" size="small" class="extra_small_button mr-1" icon="expand_less" @click="PosDecrease(indextr)"></vs-button>
                        <vs-button :disabled="indextr+1 >= data.length" size="small" class="extra_small_button mr-3" icon="expand_more" @click="PosIncrease(indextr)"></vs-button>
                        {{data[indextr].pos}} <vs-icon v-if="data[indextr].show_position == false" color="primary" class="small_icon" icon="visibility_off"></vs-icon>

                      </vs-td>      
                      <vs-td :data="data[indextr].txt_prod">
                          <div v-html="data[indextr].txt_prod + data[indextr].txt_var"></div>
                      </vs-td>  
                      <vs-td :data="data[indextr].amount">
                        <span v-show="data[indextr].amount > 0">{{data[indextr].amount}}</span>
                      </vs-td>  
                      <vs-td>
                        <vs-button size = "small" icon="edit" class="mr-1" @click="EditMember(data[indextr])"></vs-button>
                        <vs-button size = "small" icon="delete" @click="OpenConfirmDelete(data[indextr].id)"></vs-button>
                      </vs-td>                                                                                                                                                                        
                    </vs-tr>
                  </template>

                </vs-table>
              </div> 
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import {numbergroup_Types} from '../../../helper/enumHelper';
import {shipmentMergeType} from '../../../helper/enumHelper';
import DatePicker from '../DatePicker.vue';
import {shipmentStatus} from '../../../helper/enumHelper';
import ShipmentStatusCompo from '../ShipmentStatus.vue';

export default {
  name: "AddShipmentDlg",
  props:['Type'],
  components:{
    DatePicker,
    ShipmentStatusCompo
    },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('SHIPMENT.ADD_SHIPMENT.TITLE'),
      shipment_nr:"",
      numbergroup_fk:-1,
      numbergroup_fk_fail:false,
      numbergroup_fk_ok:false,   
      defaultNrGrpFk:-1,  
      numbergroups:[],
      description:"",
      description_fail:false,
      sent_date:null,
      received_date:null,
      returned_date:null,
      memberIds:[],
      deliverycondition_fk:-1,
      id:0,
      owner_fk:0,
      currency_fk:-1,
      country_fk:-1,
      fulldata:null,
      state: shipmentStatus.CREATED,
      stateDisabled:false,
      EQUAL:shipmentMergeType.EQUAL,
      EQUALPROD:shipmentMergeType.EQUALPROD,
      editmode_mem:false,
      actmember:{id:0,shipment_fk:0,pos:0,txt_prod:"",txt_var:"",amount:0,show_position:true},
      actmember_old:""
    }
  }, 
  mounted () {
    
    this.ClearForm();
    
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      
      ShowDlg(memberIds,owner_fk,currency_fk,country_fk){
        this.ClearForm();
        this.owner_fk = owner_fk;
        this.currency_fk = currency_fk;
        this.country_fk = country_fk;

        this.$store.dispatch('deliverycondition/getDeliveryConditions', { root: true },{ root: true });
        this.$store.dispatch('numbergroup/getNumbergroupsByCompany',0,{ root: true });

        if(this.defaultNrGrpFk != -1)
        {
          this.numbergroup_fk = this.defaultNrGrpFk;
        }
        this.stateDisabled = true;
        this.memberIds = memberIds;
        this.SortMember(); 
        this.dlgActive=true;
      },
      InitDlg(owner_fk,currency_fk,country_fk,data)
      {
        this.ClearForm();
        this.owner_fk = owner_fk;
        this.currency_fk = currency_fk;
        this.country_fk = country_fk;

        this.$store.dispatch('deliverycondition/getDeliveryConditions', { root: true },{ root: true });
        this.$store.dispatch('numbergroup/getNumbergroupsByCompany',0,{ root: true });

        this.shipment_nr = data.shipment_nr;
        this.numbergroup_fk = data.numbergroup_fk;
        this.description = data.description;
        this.sent_date = data.sent_date;
        this.received_date = data.received_date;
        this.returned_date = data.returned_date;
        this.memberIds = data.member;
        this.deliverycondition_fk = data.deliverycondition_fk;

        this.fulldata = data;
        this.popupTitle = this.$t('SHIPMENT.EDIT_SHIPMENT.TITLE');
        this.SortMember(); 
        this.dlgActive=true;
      },
      closed(){
        this.ClearForm();
      },
      accepted(){

          var member = [];
          for(var i = 0; i < this.memberIds.length;i++)
          {
            member.push({ordermember_fk:this.memberIds[i].id,id:0});
          }

          if(!this.IsNotNull(this.fulldata))
          {
            this.fulldata = 
            {
              id: this.id,
              description:this.description,
              numbergroup_fk:this.numbergroup_fk,
              sent_date:this.sent_date,
              received_date:this.received_date,
              returned_date:this.returned_date,
              owner_fk:this.owner_fk,
              owerntype:this.Type,
              deliverycondition_fk:this.deliverycondition_fk,
              member:member
            };

          }
          else
          {
            this.fulldata.description = this.description;
            this.fulldata.numbergroup_fk = this.numbergroup_fk,
            this.fulldata.sent_date = this.sent_date,
            this.fulldata.received_date = this.received_date,
            this.fulldata.returned_date = this.returned_date,
            this.fulldata.deliverycondition_fk = this.deliverycondition_fk
            this.fulldata.state = this.state;
          }

          this.$store.dispatch('shipment/saveShipment', this.fulldata)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('SHIPMENT.ADD_SHIPMENT.SUCCESS.SAVE')});  
                this.$emit('Success',response.id);
            }
            else
            {
              var text = this.$t('SHIPMENT.ADD_SHIPMENT.WARNING.SAVE');
              text = text.replace("*1*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('SHIPMENT.ADD_SHIPMENT.ERROR.SAVE') + error});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      PosDecrease(idx)
      {
        if(this.IsNotNull(this.fulldata))
        {
          if(idx -1 >= 0)
          {
            this.fulldata.member[idx].pos--;
            this.fulldata.member[idx-1].pos++;
          }
          this.SortMember();
        }
      },
      PosIncrease(idx)
      {
        if(this.IsNotNull(this.fulldata))
        {
          if(idx+1 < this.fulldata.member.length)
          {
            this.fulldata.member[idx].pos++;
            this.fulldata.member[idx+1].pos--;
          }
          this.SortMember();
        }
      },
      EditMember(mem){
        this.editmode_mem = true;
        this.actmember_old = JSON.stringify(mem);
        this.actmember = mem;
      },
      SaveMember(){

        if(this.actmember.pos <= 0)
        {
          this.AddMember();
        }
        else
        {
          this.editmode_mem = false;
          this.ClearActMember();
        }
        this.SortMember();
      },
      AddMember(){
        if(this.IsNotNull(this.fulldata))
        {
          this.actmember.pos = this.GetNextPos();
          this.fulldata.member.push(this.actmember);
          this.ClearActMember();
          this.editmode_mem = false;
        }
      },
      GetNextPos(){
        var nextpos = 0;
        if(this.IsNotNull(this.fulldata))
        {
          if(this.IsNotNull(this.fulldata.member))
          {
            nextpos = this.fulldata.member.length+1;
          }
            
        }
        return nextpos;
      },
      SortMember(){
        if(this.IsNotNull(this.fulldata))
        {
          if(this.IsNotNull(this.fulldata.member))
          {
              this.fulldata.member.sort((a, b) => {

              if (a.pos < b.pos) {
                return -1;
              }
              if (a.pos > b.pos) {
                return 1;
              }
              return 0;
            });
          }
            
        }

      },
      CancelMember(){
        var oldval = JSON.parse(this.actmember_old);
        this.actmember.txt_prod = oldval.txt_prod;
        this.actmember.txt_var = oldval.txt_var;
        this.actmember.amount = oldval.amount;
        this.editmode_mem = false;
        this.ClearActMember();
      },
      ClearForm(){ 
        this.shipment_nr = "";
        this.numbergroup_fk = -1;
        this.numbergroup_fk_fail = false;
        this.numbergroup_fk_ok = false;   
        this.description = "";
        this.description_fail = false;
        this.sent_date = null;
        this.received_date = null;
        this.returned_date = null;
        this.memberIds = [];
        this.owner_fk = 0;
        this.deliverycondition_fk = -1;
        this.id = 0;
        this.currency_fk = -1;
        this.country_fk = -1;

        this.fulldata = null;
        this.state = shipmentStatus.CREATED;
        this.stateDisabled = false;
        
        this.editmode_mem = false;
        this.popupTitle = this.$t('SHIPMENT.ADD_SHIPMENT.TITLE');
        this.ClearActMember();
      },
      ClearActMember(){
        this.actmember = {id:0,shipment_fk:0,pos:0,txt_prod:"",txt_var:"",amount:0,show_position:true};
        this.actmember_old = "";
      },
      ShowReceivedDate(state)
      {
          var retVal = false;
          switch(state)
          {
            case shipmentStatus.DELIVERED:
            case shipmentStatus.ONTHEWAYBACK:
            case shipmentStatus.RETURNED:
              retVal = true;
              break;
          }
          return retVal;
      },
      ShowReturnedDate(state)
      {
          var retVal = false;
          switch(state)
          {
            case shipmentStatus.RETURNED:
              retVal = true;
              break;
          }
          return retVal;
      },
      ShowSentDate(state)
      {
          var retVal = false;
          switch(state)
          {
            case shipmentStatus.RETURNED:
            case shipmentStatus.ONTHEWAY:
            case shipmentStatus.DELIVERED:
            case shipmentStatus.ONTHEWAYBACK:
            case shipmentStatus.LOST:
              retVal = true;
              break;
          }
          return retVal;
      },
      OpenConfirmMerge(id,type) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.MERGE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('SHIPMENT.MEM.QUESTION.MERGE'),
          accept: this.SendMergeRequest,
          parameters:{"id": id,"type":type}
        });
      
      },
      SendMergeRequest: function(parameters)
      {
        var data = {shipment_fk: parameters.id, type: parameters.type};
        this.$store.dispatch('shipment/mergeShipmentMember', data)
        .then(response => {
          if(response.success === true)
          {
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('SHIPMENT.MEM.SUCCESS.MERGE')}); 
            this.$emit('Success',parameters.id);
            this.dlgActive = false;
          }
          else
          {
            var text = this.$t('SHIPMENT.MEM.WARNING.MERGE').replace("*1*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('SHIPMENT.MEM.ERROR.MERGE') + ' ' +error});  
        });
      },
      OpenConfirmDelete(id) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('SHIPMENT.MEM.QUESTION.DELETE'),
          accept: this.SendDeleteRequest,
          parameters:{"id": id}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('shipment/deleteShipmentMember', parameters.id)
        .then(response => {
          if(response.success === true)
          {
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('SHIPMENT.MEM.SUCCESS.DELETE')}); 
            if(response.id > 0)
            {
              // ganze Lieferung wurde gelöscht weil letzter Eintrag entfernt wurde.
              this.$emit('Success',response.id);
              this.dlgActive = false;
            }
          }
          else
          {
            var text = this.$t('SHIPMENT.MEM.WARNING.DELETE').replace("*1*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('SHIPMENT.MEM.ERROR.DELETE') + ' ' +error});  
        });
      },
  },
  computed:{
    formValid(){
      return !this.description_fail && this.numbergroup_fk_ok;
    },
    numbergroupsForSelect(){
      return this.GetListOfNumbergroupsForSelect(numbergroup_Types.LIEFERSCHEIN);
    },
    deliveryconditions(){       
      return this.GetListOfDeliveryConditionsForSelect(this.country_fk,this.currency_fk);
    },
    shipmentMemberFull(){
      var retVal = [];

      if(this.IsNotNull(this.fulldata))
      {
        retVal = this.fulldata.member;
      }

      return retVal;
    },
    maxPos(){
        var maxpos = 50;
        if(this.IsNotNull(this.fulldata))
        {
          if(this.IsNotNull(this.fulldata.member))
          {
            maxpos = this.fulldata.member.length;
          }
            
        }
        return maxpos;
      },
  },
  watch:{
    numbergroupsForSelect(value)
    {
        this.numbergroups = value.numbergroups;
        this.defaultNrGrpFk = value.default;
    },

    numbergroup_fk(value){
      this.numbergroup_fk_fail = value <= 0;
      this.numbergroup_fk_ok = !this.numbergroup_fk_fail;
    }
  }
};
</script>
<style>
div#promptAddShipment > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddShipment{
  z-index:29999 !important;
}
div.nmb_pos > input{
  min-width:20px !important;
}
i.small_icon{
  font-size:18px !important;
}
button.extra_small_button.includeIconOnly.small,
button.extra_small_button{
  padding:4px !important;
  width: 15px !important;
  height: 15px !important;
}
</style>